<template>
  <TVModal class="welcome-modal">
    <div
      slot="body"
      class="welcome-modal__body"
    >
      <div class="welcome-modal__illustration">
        <img
          :src="imageSource"
          alt="welcome illustration"
        >
      </div>
      <Carousel
        :per-page="1"
        :value="slideIndex"
        class="welcome-modal__carousel"
        @pageChange="changeSlideIndex"
      >
        <Slide
          v-for="slide in slides"
          :key="slide.index"
        >
          <div class="welcome-modal__info">
            <h3 class="info__title">
              {{ slide.info.title }}
            </h3>

            <p class="info__description">
              {{ slide.info.description }}
            </p>
          </div>
        </Slide>
      </Carousel>
    </div>
    <div
      slot="footer"
      class="welcome-modal__actions"
    >
      <div class="actions__left">
        <TVButton
          size="big"
          theme="float"
          @click="close()"
        >
          Fechar
        </TVButton>
      </div>
      <div class="actions__right">
        <TVButton
          v-if="slideIndex && ($mq === 'x_large' || $mq === 'large')"
          size="big"
          theme="secondary"
          @click="back"
        >
          <Icon
            slot="left"
            type="arrow-left"
          />
          Anterior
        </TVButton>
        <TVButton
          v-if="slideIndex < slides.length - 1"
          size="big"
          @click="next"
        >
          Próximo
          <Icon
            v-if="$mq === 'x_large' || $mq === 'large'"
            slot="right"
            type="arrow-right"
            stroke="white"
          />
        </TVButton>
        <TVButton
          v-else
          size="big"
          @click="startTour()"
        >
          Começar!
        </TVButton>
      </div>
    </div>
  </TVModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
import TVModal from '@/components/global/TVModal'
import image1 from '@/assets/welcome-illustration-1.svg'
import image2 from '@/assets/welcome-illustration-2.svg'
import image3 from '@/assets/welcome-illustration-3.svg'
import image4 from '@/assets/welcome-illustration-4.svg'

export default {
  name: 'WelcomeModal',
  components: {
    TVModal,
    Carousel,
    Slide,
  },
  data() {
    return {
      slideIndex: 0,
      slides: [
        {
          index: 0,
          image: image1,
          info: {
            title: 'Seja bem-vindo(a) à nova SAS Educação TV!',
            description: 'A nova SAS Educação TV veio para facilitar seu acesso às videoaulas e a outros conteúdos de vídeo do SAS Educação.',
          },
        },
        {
          index: 1,
          image: image2,
          info: {
            title: 'Acesse tudo em um só lugar',
            description: 'Suas videoaulas estão reunidas e organizadas na seção Sala de Aula. É só escolher e assistir!',
          },
        },
        {
          index: 2,
          image: image3,
          info: {
            title: 'Conheça os Canais de Vídeos da SAS Educação TV',
            description: 'A SAS Educação TV tem muito mais que videoaulas! Explore os Canais de Vídeos para descobrir outros conteúdos.',
          },
        },
        {
          index: 3,
          image: image4,
          info: {
            title: 'Pronto para começar?',
            description: 'Aproveite essa nova experiência preparada com carinho para você.',
          },
        },
      ],
    }
  },
  computed: {
    imageSource() {
      return this.slides[this.slideIndex].image
    },
  },
  methods: {
    ...mapActions([
     'setOnboarding',
    ]),
    back() {
      this.slideIndex--
    },
    next() {
      this.slideIndex++
    },
    changeSlideIndex(index) {
      this.slideIndex = index
    },
    startTour() {
      this.$emit('start')
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="sass">
$min_device_medium: 569px
$max_device_medium: 767px

=mq_modal_landscape
  @media (orientation: landscape) and (max-width: $device-xs), (orientation: landscape) and (max-height: $device-s)
    @content

.welcome-modal
  .tv-modal
    padding: 0 0 24px 0
    border-radius: 16px

    +mq_modal_landscape
      padding: 0
      border-radius: 0

    &__close
      display: none

    &__body
      height: 100%

    &__footer
      border-top: 1px solid #F0F0F2
      margin-top: 20px
      padding-top: 20px

      +mq-l
      background: white

      +mq_modal_landscape
        left: unset
        width: 65%
        right: 0
        box-shadow: none

  &__body
    display: flex
    flex-direction: column
    height: 100%
    max-width: 856px
    text-align: center

    +mq-xs
      min-height: 496px

    +mq_modal_landscape
      flex-direction: row
      text-align: left

  &__illustration
    display: flex
    height: 344px
    background-color: #0954AD

    +mq-s
      height: 220px

    +mq_modal_landscape
      width: 35%
      height: 100%

    @media (orientation: landscape) and (min-height: $min_device_medium) and (orientation: landscape) and (max-height: $max_device_medium)
      height: 260px

    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center

  &__carousel
    +mq_modal_landscape
      width: 65%

  &__info
    display: flex
    flex-direction: column
    align-items: center
    box-sizing: border-box
    padding: 42px 24px 0 24px

    +mq-xs
      padding: 16px

    +mq_modal_landscape
      padding: 24px
      width: 100%
      align-items: start

    .info__title
      margin-bottom: 16px

      +mq-s
        font-size: 20px
        width: 232px

    .info__description
      max-width: 560px

  &__actions
    width: 100%
    display: flex
    padding: 0 32px

    +mq-m
      justify-content: space-between
      padding: 0 8px

    +mq_modal_landscape
      justify-content: space-between
      padding: 0 8px

    .actions__left
      width: fit-content

    .actions__right
      display: flex
      justify-content: center
      width: calc(100% - 196px)

      +mq-m
        justify-content: right
        width: auto

      +mq_modal_landscape
        justify-content: right
        width: auto

  .VueCarousel-dot
    margin-top: 0 !important
    background-color: $color-ink-lighter !important

    &--active
      background-color: $color-primary !important

    &-container
      +mq-xs
        margin-top: 0 !important

      +mq_modal_landscape
        margin-top: 0 !important

    &:focus
      outline: unset
</style>
